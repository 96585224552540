import $ from 'jquery';
import 'what-input';

window.$ = $;
import './lib/foundation-std';
// import './lib/functions';

// Ensure elements for off canvas are created/removed depending upon the current breakpoint
function cfResetOffcanvas(current_breakpoint, oc_breakpoints) {
    if ($.inArray(current_breakpoint,oc_breakpoints) === -1) {
        // bp is large +, so remove offcanvas features
        $("html").removeClass("nav-open");
        if($("#oc-overlay").length !== 0){
            $("#oc-overlay").remove();
        }
    } else {
        // add offcanvas features
        if ($("#oc-overlay").length === 0) {
            $("#oc-inner").prepend("<div id='oc-overlay'></div>");
        }
    }
    return current_breakpoint;
}


$(document).foundation();

let _navopen = false,
    _bp,
	_old_bp = Foundation.MediaQuery.current,
	_new_bp,
	resizeTimer,
	_oc_bps = ["small","medium"]
	;

// console.log(`Starting size: ${_old_bp}`);

$(document).ready(function() {
	if ($.inArray(_old_bp,_oc_bps) != -1) {
		$("#oc-inner").prepend("<div id='oc-overlay'></div>");
	}
});

$(window).on("resize",function(e) {
	clearTimeout(resizeTimer);
	resizeTimer = setTimeout(function() {
		_bp = cfResetOffcanvas(Foundation.MediaQuery.current,_oc_bps);
	  }, 300);
});

$(window).on("orientation:changed",function(e) {
	_bp = cfResetOffcanvas(Foundation.MediaQuery.current,_oc_bps);

});

$("#nav-open-btn").click(function(e) {
	$("html").toggleClass("nav-open");
	if($("html").hasClass("nav-open")){
	   _navopen = true;
	}
});

$("body").on("click","#main-menu .nav-close, #oc-overlay", function(e) {
	$("html").removeClass("nav-open");
	_navopen = false;
});

// Disable paste into fields with "nopaste" class
const npFields = document.querySelectorAll(".nopaste"), numNpFields = npFields.length;
if (numNpFields >= 1) {
    npFields.forEach(item => {
            item.addEventListener("paste", e => {
                e.preventDefault();
        });
    });
}
